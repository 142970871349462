<template>
    <div v-if="pageShow">
        <!-- 面包屑导航 -->
        <Breadcrumb :breadList="breadList">
        </Breadcrumb>
        <a-form-model class="formModal" ref="form" :rules="rules" :model="form" :label-col="labelCol"
            :wrapper-col="wrapperCol">
            <a-form-model-item prop="name" label="模板名称">
                <a-input v-model="form.name" placeholder="请输入内容" />
            </a-form-model-item>
            <a-form-model-item label="包邮开关">
                <a-switch :default-checked="form.postage == 1" @change="onPostageChange" />
                <div slot="extra">包邮开关打开，可输入包邮金额，包邮开关关闭不显示包邮金额</div>
            </a-form-model-item>
            <a-form-model-item prop="conditions" label="包邮金额">
                <a-input-number style="width:100%;" :step="0.01" :formatter=limitDecimals v-model="form.conditions"
                    :min="0" :max="100000" />
            </a-form-model-item>
            <a-form-model-item prop="way" label="计费方式">
                <a-radio-group name="radioGroup" v-model="form.way" :default-value="1">
                    <a-radio :value="1">
                        按重量
                    </a-radio>
                    <a-radio :value="2">
                        按件数
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item prop="priceAreas" label="配送区域" :wrapperCol="{ span: 20, offset: 0 }">
                <a-button type="primary" @click="showModal">
                    指定区域
                </a-button>
                <div style="color:#8F9299;">可按省市区指定运费，例如江浙沪3元包邮，西藏新疆高运费，其他地区统一运费！，运费和续费设置0元代表免运费和续费免费</div>
                <a-table v-if="form.priceAreas.length > 0" :pagination="false" :data-source="form.priceAreas" bordered
                    :rowKey="(record, index2) => index2" :columns="columns" :scroll="{ x: 1200 }">
                    <span slot="first" slot-scope="text,record,index">
                        <a-input-number @blur="dynamicValidate(index,'first')" :step="0.01" :formatter=limitDecimals style="width:100%;" :min="0"
                            v-model="record.first" placeholder="请输入" />
                        <a-form-model-item style="position:absolute;" :prop="'priceAreas.' + index + '.first'" :rules="{
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                        }"></a-form-model-item>
                    </span>
                    <span slot="firstPrice" slot-scope="text,record,index">
                        <a-input-number @blur="dynamicValidate(index,'firstPrice')" :step="0.01" :formatter=limitDecimals style="width:100%;" :min="0"
                            v-model="record.firstPrice" placeholder="请输入" />
                        <a-form-model-item  style="position:absolute;" :prop="'priceAreas.' + index + '.firstPrice'" :rules="{
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                        }"></a-form-model-item>
                    </span>
                    <span slot="dadd" slot-scope="text,record,index">
                        <a-input-number @blur="dynamicValidate(index,'dadd')" :step="0.01" :formatter=limitDecimals style="width:100%;" :min="0"
                            v-model="record.dadd" placeholder="请输入" />
                        <a-form-model-item  style="position:absolute;" :prop="'priceAreas.' + index + '.dadd'" :rules="{
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                        }"></a-form-model-item>
                    </span>
                    <span slot="addPrice" slot-scope="text,record,index">
                        <a-input-number @blur="dynamicValidate(index,'addPrice')" :step="0.01" :formatter=limitDecimals style="width:100%;" :min="0"
                            v-model="record.addPrice" placeholder="请输入" />
                        <a-form-model-item  style="position:absolute;" :prop="'priceAreas.' + index + '.addPrice'" :rules="{
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                        }"></a-form-model-item>
                    </span>
                    <span slot="action" slot-scope="text,record,index">
                        <a style="margin-right:20px;"
                            @click="eidtArea(record,index)">编辑</a>
                        <a style="margin-right:20px;" @click='deleteArea(index)' href="javascript:;">删除</a>
                    </span>
                </a-table>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button @click="$utils.linkTo('/shop/order/freightTemp')">
                    取消
                </a-button>
                <a-button :loading="loading" type="primary" @click="onSubmit" style="margin-left: 10px;">
                    保存
                </a-button>
                <br />
                <br />
                <br />
                <br />
            </a-form-model-item>
        </a-form-model>
        <a-modal title="新增运费模板" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
            :maskClosable="false" :width="800" @cancel="handleCancel">
            <ProvinceAndCity ref="areaSelector" :visible="visible" @selectAreas="selectAreas"></ProvinceAndCity>
        </a-modal>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import ProvinceAndCity from '@/components/provinceAndCity.vue'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  components: {
    Breadcrumb,
    ProvinceAndCity
  },
  data () {
    return {
      breadList: [
        {
          name: '订单管理',
          url: ''
        },
        {
          name: '运费模板',
          changeParent: true,
          url: '/shop/order/freightTemp'
        },
        {
          name: '新增运费模板',
          url: ''
        }
      ],
      pageShow: false,
      loading: false,
      visible: false,
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      defaultPriceAreasItem: {
        first: '',
        firstPrice: '',
        dadd: '',
        addPrice: '',
        areas: []
      },
      itemAll: {
        provinceName: '全国',
        provinceCode: '0',
        cityName: '',
        cityCode: ''
      },
      columns: [
        { title: '配送区域', ellipsis: false, dataIndex: 'areaNames', key: 'areaNames' },
        { title: '首重（kg）/首件（个）', width: 200, dataIndex: 'first', key: 'first', scopedSlots: { customRender: 'first' } },
        { title: '运费（元）', width: 150, dataIndex: 'firstPrice', key: 'firstPrice', scopedSlots: { customRender: 'firstPrice' } },
        { title: '续重（kg）/续件（个）', width: 200, dataIndex: 'dadd', key: 'dadd', scopedSlots: { customRender: 'dadd' } },
        { title: '续费（元）', width: 150, dataIndex: 'addPrice', key: 'addPrice', scopedSlots: { customRender: 'addPrice' } },
        { title: '操作', width: 150, fixed: 'right', key: 'action', scopedSlots: { customRender: 'action' } }
      ],
      form: {
        id: undefined,
        name: '',
        postage: 0,
        conditions: '',
        way: 1,
        priceAreas: []
      },
      rules: {
        name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
          { min: 3, max: 20, message: '请输入3到20个字符', trigger: 'blur' }
        ],
        conditions: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        priceAreas: [
          { required: true, message: '请指定配送区域', trigger: 'blur' }
        ]
      }
    }
  },
  async created () {
    const id = this.$route.query.id
    this.form.id = id
    if (id) {
      const res = await this.$store.dispatch('http', {
        api: 'freightTempInfo',
        query: { id },
        error: () => {
          console.log('出错了')
        }
      })
      if (res) {
        console.log(res, '结果')
        const { name, postage, conditions, way, priceAreas } = res
        this.form.name = name
        this.form.postage = postage
        this.form.conditions = conditions
        this.form.way = way
        this.form.priceAreas = priceAreas
        this.pageShow = true
      }
      return
    }
    this.pageShow = true
  },
  methods: {
    eidtArea (record, index) {
      this.visible = true
      setTimeout(() => {
        this.$refs.areaSelector.edit(record, index)
      }, 200)
    },
    deleteArea (index) {
      this.$store.dispatch('showConfirm', {
        message: '你确定要删除这条数据吗？',
        yes: async _ => {
          this.form.priceAreas.splice(index, 1)
        }
      })
    },
    selectAreas (e) {
      this.getAreasData(e)
    },
    getAreasData (e) {
      const { data, index } = e

      let item = this.$utils.clone(this.defaultPriceAreasItem)
      if (data === 'checkAll') {
        // 选择了全国
        item.areas.push(this.$utils.clone(this.itemAll))
        item.areaNames = '全国'
      } else {
        item.areaNames = this.getSelectNames(data)
        item.areas = (this.getSelectCode(data))
      }
      if (isNaN(index)) {
        this.form.priceAreas.push(item)
      } else {
        this.form.priceAreas[index].areas = item.areas
        this.form.priceAreas[index].areaNames = item.areaNames
      }
      this.visible = false
    },
    getSelectCode (list) {
      let selectCodeList = []
      list.forEach((item, index) => {
        // 全选或者半选 group
        if (item.checked || item.indeterminate) {
          item.districtList.forEach((item2, index2) => {
            // 如果全选省
            if (item2.checked && !item2.indeterminate) {
              selectCodeList.push({
                provinceName: item2.name,
                provinceCode: item2.areaCode,
                cityName: '',
                cityCode: ''
              })
            } else if (item2.indeterminate) {
              item2.childrens.forEach((item3, index3) => {
                if (item3.checked) {
                  selectCodeList.push({
                    provinceName: item2.name,
                    provinceCode: item2.areaCode,
                    cityName: item3.name,
                    cityCode: item3.areaCode
                  })
                }
              })
            }
          })
        }
      })
      return selectCodeList
    },
    getSelectNames (list) {
      let areaNamesString = ''
      list.forEach((item, index) => {
        if (item.checked || item.indeterminate) {
          item.districtList.forEach((item2, index2) => {
            // 如果全选
            if (item2.checked && !item2.indeterminate) {
              areaNamesString += item2.name
            }
            // 如果半选省
            if (item2.indeterminate) {
              areaNamesString += item2.name
              let cityArr = []
              item2.childrens.forEach((item3, index3) => {
                if (item3.checked) {
                  cityArr.push(item3.name)
                }
              })
              areaNamesString += '{'
              areaNamesString += cityArr.join(',')
              areaNamesString += '}'
            }
            if (item2.checked || item2.indeterminate) {
              areaNamesString += index < list.length ? ',' : ''
            }
          })
        }
      })
      return areaNamesString
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' // 这里获取上传文件的类型
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '图片格式不正确'
          })
          return reject(new Error(0)) // 如有问题那咱们通过reject 函数传一个false阻止他的上传
        }
        if (isJpgOrPng) {
          let reader = new FileReader() // 这个是通过原生的方式来获取图片的宽高
          reader.readAsDataURL(file)
          reader.onload = () => {
            const image = new Image()
            image.src = reader.result
            image.onload = () => {
              let w = image.width
              let h = image.height
              if (w !== h) {
                this.$store.dispatch('showToast', {
                  type: 'error',
                  message: '请上传1:1的图片'
                })
                return reject(new Error(0)) // //如有问题那咱们通过reject 函数传一个false阻止他的上传
              } else if (file.size > 500 * 1024) { // 102400
                this.$store.dispatch('showToast', {
                  type: 'error',
                  message: '图片大小不能超过500K,建议200k以内'
                })
                return reject(new Error(0)) // //如有问题那咱们通过reject 函数传一个false阻止他的上传
              } else {
                return resolve(true) // 如没有问题了那咱们就通过resolve函数添加一个true 让文件上传
              }
            }
          }
        }
      })
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    dynamicValidate (index, key) {
      this.$refs.form.validateField(['priceAreas.' + index + '.' + key])
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const params = this.$utils.clone(this.form)
          let apiString = this.form.id ? 'freightTempEdit' : 'freightTempAdd'
          const result = await this.$store.dispatch('http', {
            api: apiString,
            params: params,
            success: () => {
              this.$store.dispatch('showToast', { message: '模板添加成功' })
              setTimeout(() => {
                this.$utils.linkTo('/shop/order/freightTemp')
              }, 1000)
            },
            error: () => {
              this.$store.dispatch('showToast', { type: 'error', message: '出错了!' })
            },
            complete: () => {
              this.loading = false
            }
          })
          console.log(result, 'resutl')
        }
      })
    },
    onPostageChange (checked) {
      this.form.postage = checked ? 1 : 0
    },
    showModal () {
      this.visible = true
      setTimeout(() => {
        this.$refs.areaSelector.edit()
      }, 200)
    },
    handleOk (e) {
      //   this.confirmLoading = true
      //   this.visible = false
      //   this.confirmLoading = false
      this.$refs.areaSelector.submit()
    },
    handleCancel (e) {
      //   console.log('Clicked cancel button')
      this.visible = false
    },
    limitDecimals (value) {
      const reg = /^(-)*(\d+)\.(\d\d).*$/
      if (typeof value === 'string') {
        return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : ''
      } else if (typeof value === 'number') {
        return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : ''
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toolTip {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tips {
    color: rgba(24, 144, 255, 1);
    background: rgba(230, 247, 255, 1);
    padding: 10px 50px;
    border: 1px solid rgb(215, 231, 247);
    margin-top: 10px;

    :first-child {
        font-weight: bold;
        font-size: 20px;
    }
}
</style>
