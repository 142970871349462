<template>
    <div>
        <div class="group" v-for="(item, index) in list" :key="index">
            <div class="group_left">
                <a-checkbox v-model="item.checked" :indeterminate="item.indeterminate" @change="(e) => { onChangeGroup(e, index) }">
                    {{ item.groupName }}
                </a-checkbox>
            </div>
            <div class="group_right">
                    <span v-for="(item2, index2) in item.districtList" :key="index2">
                        <a-checkbox v-model="item2.checked" :indeterminate="item2.indeterminate"  @change="(e) => { onChangeProvince(e, index,index2) }">
                            {{ item2.name }}
                        </a-checkbox>
                        <a-dropdown v-model="item2.visible" :trigger="['click']">
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()"><a-icon type="down" /></a>
                            <a-menu slot="overlay">
                                <a-menu-item v-for="(item3, index3) in item2.childrens" :key="index3">
                                    <a-checkbox  v-model="item3.checked" @change="(e) => { onChangeCity(e,index,index2,index3) }">
                                        {{item3.name}}
                                    </a-checkbox>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </span>
            </div>
        </div>
        <div class="group">
            <div class="group_left">
                <a-checkbox v-model="checkAll" @change="(e) => { onChangeCountry(e) }">
                   全国
                </a-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['visible'],
  data () {
    return {
      checkAll: false,
      list: [],
      index: null,
      areas: undefined
    }
  },
  methods: {
    setAreas () {
      console.log(this.areas, this.list, '设置值')
      if (this.areas.length === 1 && this.areas[0].provinceCode === '0') {
        // 选中全国
        this.checkAll = true
        this.onChangeCountry({ target: { checked: true } })
      } else {
        // 选中全省
        this.areas.forEach((item) => {
          // 选中全省
          if (item.provinceCode && !item.cityCode) {
            this.list.forEach((ele1, inx1) => {
              ele1.districtList.forEach((ele2, inx2) => {
                if (ele2.areaCode === item.provinceCode) {
                  this.onChangeProvince({ target: { checked: true } }, inx1, inx2)
                }
              })
            })
          }
          //   半选省
          if (item.provinceCode && item.cityCode) {
            console.log(item.provinceCode, item.cityCode, 'item.cityCode')
            this.list.forEach((ele1, inx1) => {
              ele1.districtList.forEach((ele2, inx2) => {
                if (ele2.areaCode !== item.provinceCode) {
                  return
                }
                ele2.childrens.forEach((ele3, inx3) => {
                  if (ele3.areaCode === item.cityCode) {
                    this.onChangeCity({ target: { checked: true } }, inx1, inx2, inx3)
                  }
                })
              })
            })
          }
        })
      }
    },
    edit (record, index) {
      this.index = index
      if (record) {
        const { areas } = record
        this.areas = areas
        this.getProvinceCityCounty({ type: 'edit' })
      } else {
        this.getProvinceCityCounty()
      }
    },
    submit () {
      if (this.checkAll) {
        this.$emit('selectAreas', { data: 'checkAll', index: this.index })
      } else {
        this.$emit('selectAreas', { data: this.list, index: this.index })
      }
    },
    onChangeCountry (e) {
      const bol = e.target.checked
      this.list.forEach((item, index) => {
        this.list[index].checked = bol
        this.list[index].indeterminate = undefined
        this.setGroupChecked(index, bol)
      })
    },
    onChangeGroup (e, index) {
      let bol = e.target.checked
      this.list[index].checked = bol
      this.list[index].indeterminate = false
      this.setGroupChecked(index, bol)
      this.setAllChecked()
    },
    onChangeProvince (e, index, index2) {
      let bol = e.target.checked
      this.list[index].districtList[index2].checked = bol
      this.list[index].districtList[index2].indeterminate = false
      //   设置分组 checkbox 的全选 半选状态
      this.setGroupIndeterminate(index)
      this.setAllChecked()
      this.list[index].districtList[index2].childrens.forEach((item3, index3) => {
        this.setCityChecked(index, index2, index3, bol)
      })
    },
    onChangeCity (e, index, index2, index3) {
      let bol = e.target.checked
      //  省的选中 全选半选
      this.setProvinceIndeterminate(index, index2)
      //   组的 全选半选
      this.list[index].districtList[index2].childrens[index3].checked = bol
      this.$forceUpdate()
    },
    setAllChecked () {
      let stringArr = []
      let groupHalfChecked = false
      this.list.forEach((item, index) => {
        stringArr.push(item.checked ? 'checked' : 'unchecked')
        groupHalfChecked = groupHalfChecked || !!item.indeterminate
      })
      if (groupHalfChecked) {
        this.checkAll = false
      } else if (stringArr.includes('checked') && !stringArr.includes('unchecked')) {
        this.checkAll = true
      } else if (stringArr.includes('checked') && stringArr.includes('unchecked')) {
        this.checkAll = false
      }
      this.$forceUpdate()
    },
    setGroupChecked (index, bol) {
      this.list[index].districtList.forEach((item2, index2) => {
        this.list[index].districtList[index2].checked = bol
        this.list[index].districtList[index2].indeterminate = undefined
        this.setProvinceChecked(index, index2, bol)
      })
      this.$forceUpdate()
    },
    setProvinceChecked (index, index2, bol) {
      this.list[index].districtList[index2].childrens.forEach((item3, index3) => {
        this.setCityChecked(index, index2, index3, bol)
      })
      this.$forceUpdate()
    },
    setCityChecked (index, index2, index3, bol) {
      this.list[index].districtList[index2].childrens[index3].checked = bol
      this.$forceUpdate()
    },
    isGroupHalfCheck (index) {
      let stringArr = []
      let groupHalfChecked = false
      this.list[index].districtList.forEach((item1, index1) => {
        stringArr.push(item1.checked ? 'checked' : 'unchecked')
        groupHalfChecked = groupHalfChecked || !!item1.indeterminate
      })
      if ((stringArr.includes('checked') && stringArr.includes('unchecked')) || groupHalfChecked) {
        return 'halfChecked'
      } else if (stringArr.includes('checked')) {
        return 'allChecked'
      } else {
        return 'unChecked'
      }
    },
    isProvinceHalfCheck (index, index2) {
      let stringArr = []
      this.list[index].districtList[index2].childrens.forEach((item1, index1) => {
        stringArr.push(item1.checked ? 'checked' : 'unchecked')
      })
      if (stringArr.includes('checked') && stringArr.includes('unchecked')) {
        return 'halfChecked'
      } else if (stringArr.includes('checked')) {
        return 'allChecked'
      } else {
        return 'unChecked'
      }
    },
    setGroupIndeterminate (index) {
      switch (this.isGroupHalfCheck(index)) {
        case 'halfChecked':
          this.list[index].indeterminate = true
          break
        case 'allChecked':
          this.list[index].checked = true
          this.list[index].indeterminate = undefined
          break
        case 'unChecked':
          this.list[index].checked = false
          this.list[index].indeterminate = undefined
          break
      }
      this.setAllChecked()
    },
    setProvinceIndeterminate (index, index2) {
      switch (this.isProvinceHalfCheck(index, index2)) {
        case 'halfChecked':
          this.list[index].districtList[index2].indeterminate = true
          break
        case 'allChecked':
          this.list[index].districtList[index2].checked = true
          this.list[index].districtList[index2].indeterminate = undefined
          break
        case 'unChecked':
          this.list[index].districtList[index2].checked = false
          this.list[index].districtList[index2].indeterminate = undefined
          break
      }
      this.setGroupIndeterminate(index)
    },
    async getProvinceCityCounty (obj) {
      const self = this
      const res = await this.$store.dispatch('http', {
        api: 'districtGrouplist',
        success: () => {
        },
        error: () => {
          console.log('出错啦')
        }
      })
      this.list = res || []
      this.checkAll = false
      if (obj && obj.type === 'edit') {
        self.setAreas()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
    display: flex;
    margin-bottom:18px;
}
.group_left {
    width:150px;
}
.group_right {
    flex:1;
    span {
        display: inline-block;
        width:33%;
    }
}
</style>
